import {
  ActionButton,
  AsyncMultiSelect,
  BoardPreview,
  Button,
  Divider,
  Input,
  Modal,
  NotificationBanner,
  Spacer,
  encodeBoardCharacters,
  useToasts,
} from "@vestaboard/installables";
import {
  Body,
  Medium,
  SubTitle,
} from "@vestaboard/installables/lib/components/Typography";
import { Box, CircularProgress } from "@mui/material";
import {
  checkSetupComplete,
  getChannels,
  getSubscriptionInfo,
  removeBoard,
  syncToChannel,
  updateSubscriptionTitle,
} from "../api";
import { useCallback, useEffect, useMemo, useState } from "react";

import axios from "axios";
import { useIsQuietHours } from "../hooks/useQuietHours";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useUpdateDetails } from "../hooks/useUpdateDetails";
import { useVestaboardQueryParams } from "@vestaboard/installables/lib/hooks/useVestaboardParams";

export interface IChannelOption {
  id: string;
  name: string;
}

export const Settings = () => {
  const { updateTitle } = useUpdateDetails();
  const { addToast } = useToasts();
  const { subscriptionId, subscriptionConfigToken } =
    useVestaboardQueryParams();
  const [error, setError] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const [title, setTitle] = useState("");
  const [boardId, setBoardId] = useState("");
  const [channels, setChannels] = useState<Array<IChannelOption>>([]);
  const [setupComplete, setSetupComplete] = useState(false);
  const [slackTeamName, setSlackTeamName] = useState("");
  const [channelOptions, setChannelOptions] = useState<Array<IChannelOption>>(
    []
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [showAuthMessageOnSuccess, setShowAuthMessageOnSuccess] =
    useLocalStorage("authenticateSuccess", true);

  const { isQuietHours, formattedQuietHours } = useIsQuietHours({ boardId });

  const saveSubscription = useCallback(
    async (subscriptionId: string, subscriptionConfigToken: string) => {
      const { subscription } = await getSubscriptionInfo(
        subscriptionConfigToken
      );
      const boardId = subscription.board[0].id;
      const boardTitle = subscription.board[0].title;
      if (boardTitle && boardId && subscriptionConfigToken && subscriptionId) {
        updateTitle({ title: boardTitle, subscriptionId });
        await axios.post("/api/board", {
          subscriptionId,
          subscriptionConfigToken,
          boardId,
          boardTitle,
        });
        setError("");
        return true;
      } else {
        setError("There was an error authorizing your Vestaboard");
        return false;
      }
    },
    [updateTitle]
  );

  useEffect(() => {
    if (subscriptionConfigToken) {
      getSubscriptionInfo(subscriptionConfigToken).then(({ subscription }) => {
        if (subscription?.title) {
          // initialize title with user title if they customized their subscription
          setTitle(subscription?.title);
          const boardId = subscription.board[0].id;
          setBoardId(boardId);
        }
        const boardId = subscription.board[0].id;
        setBoardId(boardId);
      });
    }
  }, [subscriptionConfigToken]);

  useEffect(() => {
    boardId &&
      checkSetupComplete(boardId).then((resp) => {
        setChannels(resp.syncedChannels);
        setSetupComplete(resp.setupComplete);
        setSlackTeamName(resp.slackTeamName);
      });
  }, [boardId]);

  useEffect(() => {
    slackTeamName &&
      subscriptionConfigToken &&
      getChannels(boardId, subscriptionConfigToken).then((data) => {
        setChannelOptions(data.channels);
      });
  }, [boardId, subscriptionConfigToken, slackTeamName, addToast]);

  useEffect(() => {
    if (showAuthMessageOnSuccess && setupComplete && slackTeamName) {
      addToast(`Authenticated with ${slackTeamName}`, {
        appearance: "success",
      });
      // only show once after authentication complete
      setShowAuthMessageOnSuccess(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuthMessageOnSuccess, setupComplete, slackTeamName]);

  // has to line up exactly with the Redirect URI in /auth/board
  const redirectUri = useMemo(
    () => `https://${window.location.host}/auth/board?state=${boardId}&external-browser`,
    [boardId]
  );

  const botScopes = [
    "chat:write",
    "users:read",
    "commands",
    "channels:read",
    "app_mentions:read",
    "groups:read",
    "groups:history",
    "channels:history",
  ];

  const userScopes = [""];

  const scope = `${botScopes.join(",")}&user_scope=${userScopes.join(",")}`;

  const authUrl =
    window.location.hostname && !window.location.hostname.includes("slack")
      ? `https://slack.com/oauth/v2/authorize?client_id=3945848640.4917521606050&scope=${scope}&redirect_uri=${redirectUri}`
      : `https://slack.com/oauth/v2/authorize?client_id=3945848640.4903258990658&scope=${scope}&redirect_uri=${redirectUri}`;

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          minHeight: "100vh",
          padding: 16,
          paddingTop: 32,
          backgroundColor: "#25282a",
        }}
      >
        {!boardId ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <CircularProgress data-testid={"loader"} />
          </Box>
        ) : (
          <>
            <Box
              style={{
                textAlign: "left",
                width: "100%",
                maxWidth: 600,
              }}
            >
              <NotificationBanner
                text={formattedQuietHours}
                visible={isQuietHours}
              />
              <SubTitle>Description</SubTitle>
              <Spacer size={"large"} />
              <Body>
                Link Vestaboard with your Slack organization to designate a
                specific channel to post to Vestaboard, compose a Vestaboard
                message on the fly or send a past Slack message to Vestaboard.
              </Body>
              <Spacer size={"medium"} />
              <Box style={{ display: "flex", flexDirection: "row" }}>
                <ActionButton
                  onClick={() => {
                    setOpenPreview(true);
                  }}
                >
                  Preview
                </ActionButton>
                <div style={{ width: 16 }}></div>
                <ActionButton
                  onClick={() => {
                    window.open("http://vestaboard.com/help/slack");
                  }}
                >
                  Help
                </ActionButton>
              </Box>
              <Spacer size={"large"} />
              <Divider />
              <Spacer size={"medium"} />
              <SubTitle>Channel Title</SubTitle>
              <Input
                label={""}
                placeholder={"Slack"}
                value={title}
                onValueChange={(v) => {
                  setTitle(v);
                }}
              />
              <Spacer size={"medium"} />
              <Button
                buttonType="white"
                onClick={async () => {
                  subscriptionId &&
                    subscriptionConfigToken &&
                    (await updateSubscriptionTitle(
                      subscriptionId,
                      subscriptionConfigToken,
                      title
                    ));
                  addToast("Channel updated", {
                    appearance: "success",
                  });
                }}
                disabled={false}
              >
                Update Channel Title
              </Button>
              <Spacer size={"large"} />
              {setupComplete && (
                <>
                  <Divider />
                  <Spacer size={"medium"} />
                  <SubTitle>Synced Channel</SubTitle>
                  <Spacer size={"medium"} />
                  <Body>
                    You can designate a specific Slack channel that takes any
                    message sent to it and posts to this Vestaboard.
                  </Body>
                  <Spacer size={"small"} />

                  <Medium>
                    To sync a channel, first invite @Vestaboard to the channel.
                  </Medium>
                  <Spacer size={"medium"} />
                  <AsyncMultiSelect
                    labelKeyName="name"
                    descriptionKeyName="id"
                    disableCloseOnSelect={false}
                    // @ts-ignore
                    onOptionSelect={setChannels}
                    label="Channels"
                    placeholder="#vestaboard"
                    filterSelectedOptions={false}
                    // @ts-ignore
                    defaultSelectedOptions={channels}
                    // @ts-ignore
                    options={
                      searchInput
                        ? channelOptions.filter((option) =>
                            option.name.includes(searchInput)
                          )
                        : channelOptions
                    }
                    loading={!setupComplete || channelOptions.length === 0}
                    onInputChange={(val) => {
                      setSearchInput(val);
                    }}
                  />
                  <Spacer size={"medium"} />
                  {boardId && (
                    <Button
                      buttonType="white"
                      onClick={async () => {
                        subscriptionId &&
                          subscriptionConfigToken &&
                          (await syncToChannel(
                            channels,
                            boardId,
                            subscriptionConfigToken
                          ));
                        addToast("Sync successful", {
                          appearance: "success",
                        });
                      }}
                      disabled={false}
                    >
                      Sync Vestaboard to Channel(s)
                    </Button>
                  )}
                </>
              )}
              <Spacer size={"large"} />

              <Divider />
              <Spacer size={"medium"} />
              <SubTitle>
                {setupComplete
                  ? "Authorization Complete"
                  : "Authorization Needed"}
              </SubTitle>
              <Spacer size={"medium"} />
              <Body>
                {setupComplete
                  ? `You have authorized this Vestaboard to be used with the ${slackTeamName} Slack organization.`
                  : `To begin using Slack commands with your Vestaboard, a Slack organization admin will need to authorize access to your account first. Click the button below to start that process.`}
              </Body>
              <Spacer size={"medium"} />
              {setupComplete && (
                <>
                  <Body>
                    Type /vestaboard in Slack to trigger the slackbot and learn
                    more about the functionality.
                  </Body>
                  <Spacer size={"medium"} />
                </>
              )}
              {error && (
                <>
                  <Body>{error}</Body>
                  <Spacer size={"medium"} />
                </>
              )}
            </Box>
            <Box
              style={{
                width: "100%",
                maxWidth: 600,
                display: "flex",
                flexDirection: "column",
                paddingTop: 24,
                marginLeft: 16,
                marginRight: 16,
              }}
            >
              {subscriptionId && subscriptionConfigToken ? (
                <Button
                  buttonType="white"
                  onClick={async () => {
                    if (!setupComplete) {
                      return setOpenWarning(true);
                    }
                    // save board to db
                    const saved =
                      subscriptionId &&
                      subscriptionConfigToken &&
                      (await saveSubscription(
                        subscriptionId,
                        subscriptionConfigToken
                      ));
                    if (saved) {
                      setShowAuthMessageOnSuccess(true);
                      // STAGING and PROD ADD TO SLACK BUTTON
                      window.open(authUrl);
                    }
                  }}
                >
                  {setupComplete ? "Manage Slack" : "Authorize Access to Slack"}
                </Button>
              ) : (
                <Body>
                  Error: This installable requires subscription_id and
                  subscription_configuration_token parameters
                </Body>
              )}
              <Spacer size={"medium"} />
            </Box>
          </>
        )}

        {setupComplete && (
          <Box
            style={{
              width: "100%",
              maxWidth: 600,
              display: "flex",
              flexDirection: "column",
              paddingTop: 24,
              marginLeft: 16,
              marginRight: 16,
            }}
          >
            {subscriptionId && subscriptionConfigToken && (
              <Button
                buttonType="danger"
                onClick={async () => {
                  return setOpenWarning(true);
                }}
              >
                {"Revoke Slack Permissions"}
              </Button>
            )}
            <Spacer size={"medium"} />
          </Box>
        )}
      </Box>
      <Modal
        visible={openWarning && !setupComplete}
        fullScreenMobile
        onClose={() => {
          setOpenWarning(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <SubTitle>Do you want to proceed?</SubTitle>
          <Spacer size={"large"} />
          <Body>
            Authorizing access will allow other team members within your Slack
            organization to message this Vestaboard from within Slack.
          </Body>
          <Spacer size={"large"} />
          {subscriptionId && subscriptionConfigToken && (
            <Button
              buttonType="white"
              onClick={async () => {
                // save board to db
                const saved = await saveSubscription(
                  subscriptionId,
                  subscriptionConfigToken
                );
                if (saved) {
                  // STAGING and PROD ADD TO SLACK BUTTON
                  setShowAuthMessageOnSuccess(true);
                  window.open(authUrl);
                }
              }}
            >
              {"Authorize Access to Slack"}
            </Button>
          )}
        </Box>
      </Modal>
      <Modal
        visible={openWarning && setupComplete}
        fullScreenMobile
        onClose={() => {
          setOpenWarning(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <SubTitle>Are you sure?</SubTitle>
          <Spacer size={"large"} />
          <Body>
            Revoking permissions will unlink your Slack organization with this
            Vestaboard, and Slack users will lose the ability to post messages
            to this Vestaboard.
          </Body>
          <Spacer size={"large"} />
          {subscriptionId && subscriptionConfigToken && (
            <Button
              buttonType="danger"
              onClick={async () => {
                // save board to db
                await removeBoard(subscriptionConfigToken, boardId);
                window.location.reload();
              }}
            >
              {"Revoke Slack Permissions"}
            </Button>
          )}
        </Box>
      </Modal>

      <Modal
        visible={openPreview}
        transparent
        fullScreenMobile
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <BoardPreview
            isFavorite={false}
            characters={encodeBoardCharacters(
              `                        Great work closing    that new deal,Jane!   #sales-team`
            )}
          />
        </Box>
      </Modal>
    </>
  );
};
// ADD TO SLACK BUTTON if the slack store team requires it.
// <img
// alt="Add to Slack"
// height="40"
// width="139"
// src="https://platform.slack-edge.com/img/add_to_slack.png"
// srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
// />
